import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

const LegalDisclaimerPage = ({ pageContext }) => {
  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>Legal Disclaimer</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>
              <span style={{ color: "#374151" }}>Legal Disclaimer</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                By accessing and using the Travel Lingual website (the
                "Website"), you agree to comply with and be bound by the
                following terms and conditions. If you do not agree to these
                terms and conditions, please refrain from using our Website.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                No Legal or Professional Advice
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                The content provided on Travel Lingual is for informational and
                educational purposes only. It does not constitute legal,
                financial, medical, or professional advice. You should not rely
                on the information on this Website as an alternative to
                professional advice. Always seek the advice of qualified
                professionals for specific concerns related to your personal,
                financial, health, or legal situation.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>Accuracy of Information</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                While we strive to provide accurate and up-to-date information,
                we make no representations or warranties of any kind, express or
                implied, regarding the completeness, accuracy, reliability,
                suitability, or availability of the information, products,
                services, or related graphics on the Website. Any reliance you
                place on such information is strictly at your own risk.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>External Links</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                Our Website may contain links to external websites that are not
                under our control. We are not responsible for the content of
                these external sites, and their inclusion on our Website does
                not imply endorsement or affiliation. We cannot guarantee the
                accuracy, completeness, or availability of the information on
                linked websites.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>Limitation of Liability</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                Travel Lingual, its employees, agents, and affiliates will not
                be liable for any direct, indirect, incidental, consequential,
                or punitive damages arising out of your access to or use of the
                Website. This includes any loss of data, profits, business, or
                reputation.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                Copyright and Intellectual Property
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                All content on Travel Lingual, including text, graphics, images,
                logos, and audio or video clips, is the property of Travel
                Lingual or its content suppliers and is protected by copyright
                and intellectual property laws. Unauthorized use, reproduction,
                or distribution of this content is strictly prohibited.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>Privacy Policy</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                Please refer to our Privacy Policy to understand how we collect,
                use, and protect your personal information when you use our
                Website.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                Changes to Terms and Conditions
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                Travel Lingual reserves the right to update or revise these
                terms and conditions at any time without notice. It is your
                responsibility to review these terms periodically for changes.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>Governing Law</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                These terms and conditions are governed by and construed in
                accordance with the laws of [Your Jurisdiction], and you agree
                to submit to the exclusive jurisdiction of the courts located in
                [Your Jurisdiction] for the resolution of any disputes.
              </span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>Contact Information</span>
            </p>
            <p>
              <span style={{ color: "#374151" }}>
                If you have any questions or concerns regarding this legal
                disclaimer, please contact us at info@travel-lingual.com.
              </span>
            </p>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default LegalDisclaimerPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Legal Disclaimer | Travel-Lingual"}
      description={
        "Legal Disclaimer. By accessing and using the Travel Lingual website, you agree to comply with and be bound by the following terms and conditions."
      }
      pathname={location.pathname}
    />
  );
};
